import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Row, Spin } from "antd";
import { Route, Routes } from "react-router-dom";
import NotFound from "./not-found/not-found.page";

export type AppRoute = Readonly<{
  url: string;
  Component: LazyExoticComponent<FC> | null;
  text: string;
}>;

const GetStarted = lazy(() => import("./get-started/get-started.page"));
const Home = lazy(() => import("./home/home.page"));
const Teams = lazy(() => import("./teams/teams.page"));
const TeamsDetailed = lazy(() => import("./teams-detailed/teams-detailed.page"));
const Staff = lazy(() => import("./staff/staff.page"));
const StaffDetailed = lazy(() => import("./staff-detailed/staff-detailed.page"));
const Contacts = lazy(() => import("./contacts/contacts.page"));
const ContactsDetailed = lazy(() => import("./contacts-detailed/contacts-detailed.page"));
const Tasks = lazy(() => import("./tasks/tasks.page"));
const CallHistory = lazy(() => import("./call-history/call-history.page"));
const Insights = lazy(() => import("./insights/insights.page"));
const Notes = lazy(() => import("./notes/notes.page"));
const ContactUs = lazy(() => import("./contact-us/contact-us.page"));
const Whatsapp = lazy(() => import("./whatsapp/whatsapp.page"));
const LeadStage = lazy(() => import("./lead-stage/lead-stage.page"));
const LeadGroup = lazy(() => import("./lead-group/lead-group.page"));
const Reports = lazy(() => import("./reports/reports.page"));
const OrgReports = lazy(() => import("./reports/org-reports.page"));
const Settings = lazy(() => import("./settings/settings.page"));
const OrgSettings = lazy(() => import("./settings/org-settings"));
const Integrations = lazy(() => import("./integrations/integrations.page"));
const Integration = lazy(() => import("./integrations/integration.page"));
const WpChat = lazy(() => import("./wp-chat/wp-chat.page"));
const PaymentSuccess = lazy(() => import("./payment-success/payment-success.page"));
const PaymentFailed = lazy(() => import("./payment-failed/payment-failed.page"));

const LeadCleanup = lazy(() => import("./lead-cleanup/lead-cleanup.page"));
const Automations = lazy(() => import("./automations/automations.page"));
const CreateNewAutomation = lazy(() => import("./automations/create-new-automation.page"));
const CreateAutomationFromScratch = lazy(() => import("./automations/create-automation-from-scratch.page"));
const CreateAutomationFromTemplate = lazy(() => import("./automations/create-automation-from-template.page"));
const EditAutomation = lazy(() => import("./automations/edit-automation.page"));

const Broadcast = lazy(() => import("./broadcast/broadcast.page"));
const BroadcastDetails = lazy(() => import("./broadcast-details/broadcast.details.page"));
const NewBroadcast = lazy(() => import("./new-broadcast/new.broadcast.page"));

export const AppRoutesObject = {
  Broadcast: {
    url: "/broadcast",
    Component: Broadcast,
    text: "Broadcast"
  },
  BroadcastDetails: {
    url: "/broadcast-details",
    Component: BroadcastDetails,
    text: "Broadcast"
  },
  NewBroadcast: {
    url: "/new-broadcast",
    Component: NewBroadcast,
    text: "Broadcast"
  },
  GetStarted: {
    url: "/get-started",
    Component: GetStarted,
    text: "Get Started"
  },
  Home: {
    url: "/",
    Component: Home,
    text: "Home"
  },
  LeadStage: {
    url: "/lead-stage",
    Component: LeadStage,
    text: "Lead Stage"
  },
  LeadGroup: {
    url: "/lead-group",
    Component: LeadGroup,
    text: "Lead Group"
  },
  Teams: {
    url: "/orgs",
    Component: Teams,
    text: "Teams"
  },
  TeamsDetailed: {
    url: "/orgs/:orgId",
    Component: TeamsDetailed,
    text: ""
  },
  Staff: {
    url: "/team",
    Component: Staff,
    text: "Team Members"
  },
  StaffDetailed: {
    url: "/team/:memberId",
    Component: StaffDetailed,
    text: ""
  },
  Contacts: {
    url: "/contacts",
    Component: Contacts,
    text: "Contacts"
  },
  ContactsNew: {
    url: "/contacts-new",
    Component: Contacts,
    text: "Contacts"
  },
  ContactsDetailed: {
    url: "/contacts/:customerId",
    Component: ContactsDetailed,
    text: ""
  },
  Tasks: {
    url: "/tasks",
    Component: Tasks,
    text: "To Dos"
  },
  CallHistory: {
    url: "/call-history",
    Component: CallHistory,
    text: "Call History"
  },
  Insights: {
    url: "/insights",
    Component: Insights,
    text: "Insights"
  },
  Notes: {
    url: "/notes",
    Component: Notes,
    text: "Notes"
  },
  ContactUs: {
    url: "/contact-us",
    Component: ContactUs,
    text: ""
  },
  Whatsapp: {
    url: "/whatsapp",
    Component: Whatsapp,
    text: "Whatsapp"
  },
  Reports: {
    url: "/reports",
    Component: Reports,
    text: "Reports"
  },
  OrgReports: {
    url: "/reports/:orgId",
    Component: OrgReports,
    text: ""
  },
  Settings: {
    url: "/settings",
    Component: Settings,
    text: "Settings"
  },
  OrgSettings: {
    url: "/settings/:orgId",
    Component: OrgSettings,
    text: ""
  },
  Integrations: {
    url: "/integrations",
    Component: Integrations,
    text: "Integrations"
  },
  Integration: {
    url: "/integrations/:integrationId",
    Component: Integration,
    text: ""
  },
  PaymentSuccess: {
    url: "/payment-success",
    Component: PaymentSuccess,
    text: ""
  },
  PaymentFailed: {
    url: "/payment-failed",
    Component: PaymentFailed,
    text: ""
  },
  LeadCleanup: {
    url: "/lead-cleanup",
    Component: LeadCleanup,
    text: ""
  },
  Automations: {
    url: "/automations",
    Component: Automations,
    text: "Automations"
  },
  CreateNewAutomation: {
    url: "/automations/new",
    Component: CreateNewAutomation,
    text: ""
  },
  CreateAutomationFromScratch: {
    url: "/automations/new/base",
    Component: CreateAutomationFromScratch,
    text: ""
  },
  CreateAutomationFromTemplate: {
    url: "/automations/new/:templateId",
    Component: CreateAutomationFromTemplate,
    text: ""
  },
  EditAutomation: {
    url: "/automations/:automationId",
    Component: EditAutomation,
    text: ""
  },
  WpChat: {
    url: "/chat-new",
    Component: WpChat,
    text: ""
  }
} as {
  [x: string]: AppRoute;
};

export const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <Row
          justify="center"
          align="middle"
          style={{
            minHeight: "100vh",
            minWidth: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Spin spinning={true} />
        </Row>
      }
    >
      <Routes>
        <Route path="*" element={<NotFound />} />
        {Object.values(AppRoutesObject).map(({ url, Component }: AppRoute) => {
          if (Component) {
            return <Route key={url} path={url} element={<Component />} />;
          } else return null;
        })}
      </Routes>
    </Suspense>
  );
};

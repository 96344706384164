import { Topbar } from "./topbar/topbar.component";
import { contactUs, isScreenSmaller } from "../../utils/utils";
import { TopStrip } from "./top-strip/top-strip.component";
import { PropsWithChildren } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Drawer, Layout, Menu, Row, Spin } from "antd";
import { Button, Typography } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  PhoneOutlined,
  PlusCircleOutlined,
  YoutubeOutlined,
  HomeOutlined,
  TeamOutlined,
  BarChartOutlined,
  ContactsOutlined,
  SwitcherOutlined,
  WhatsAppOutlined,
  FormOutlined,
  PieChartOutlined,
  SettingOutlined,
  AlignLeftOutlined,
  WarningFilled,
  SnippetsOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { selectSiderCollapsed } from "../../store/global/global.selector";
import { setUpgradeOrgId, setSidebar } from "../../store/global/global.reducer";
import {
  selectSummariesWithFlags,
  showPendingActionAlert,
} from "../../store/api/api.selector";
import {
  useGetMemberQuery,
  useGetSummariesQuery,
  useLazyLoginOrgQuery,
  useUsersSyncQuery,
} from "../../store/api/api";
import { AppRoute, AppRoutesObject } from "../../pages/app-routes.component";
import { selectOrgIdToPayFor } from "../../store/api/api.selector";
import { New } from "../new/new.component";
import Logo from "../../assets/images/logo.png";
import {
  selectIsFreeTier,
  selectUserPaidStatus,
} from "../../store/api/api.selector";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { PurchaseButton } from "../purchase/purchase-button.component";
import { PayNowButton } from "../purchase/pay-now-button.component";
import Pro from "../../assets/images/pro.svg";
import ChevronRightWhite from "../../assets/images/chevron-right-white.svg";
import { getPlanLevel } from "../purchase/constants";
dayjs.extend(relativeTime);

type MenuItem = Required<MenuProps>["items"][number];

export const AppLayout = (props: Readonly<PropsWithChildren>) => {
  const { isTrialUser } = useUsersSyncQuery(undefined, {
    selectFromResult: ({ data }) => selectUserPaidStatus(data),
  });

  return (
    <div className="flex flex-col min-h-screen max-h-screen h-screen overflow-hidden w-full max-w-full min-w-full">
      {isTrialUser ? <MajorNotification /> : null}
      <Layout className="grow" hasSider>
        <Navigation />
        <Layout className="flex flex-col">
          <Topbar className="flex items-center border-b-2 border-[#dfe6ed] bg-white min-h-16 justify-between" />
          <TopStrip />
          <main className="grow bg-white relative overflow-auto flex flex-col">
            {props.children}
          </main>
        </Layout>
      </Layout>
    </div>
  );
};

const MajorNotification = () => {
  const { data: usersSync } = useUsersSyncQuery();

  return (
    <div className="bg-[#FFE6A6] flex justify-center items-center py-1 px-4 gap-2.5 text-sm">
      <div className="inline text-[hsl(47,68%,18%)]">
        Your free trial ends{" "}
        <span className="font-semibold">
          {usersSync?.current_subscription?.subscription?.valid_till
            ? dayjs(
                usersSync?.current_subscription?.subscription?.valid_till
              ).fromNow()
            : ""}
          {". "}
        </span>{" "}
        If your like the experience, subscribe to paid plan
      </div>
      <a
        href="https://www.superfone.in/pricing"
        target="_blank"
        rel="noreferrer"
      >
        <button className="px-2 py-1 gap-2.5 flex items-center justify-center bg-[#FFF6D8] rounded-md">
          <span className="text-[#4F410F] font-semibold text-xs">
            View plans
          </span>
        </button>
      </a>
    </div>
  );
};

const Navigation = () => {
  const collapsed = useSelector(selectSiderCollapsed);
  const dispatch = useDispatch();
  const onDrawerClosed = () => {
    dispatch(setSidebar(false));
  };

  return isScreenSmaller ? (
    <Drawer
      open={collapsed}
      placement="left"
      onClose={onDrawerClosed}
      title={
        <Link to="/">
          <div className="flex gap-2 items-center px-4">
            <img src={Logo} alt="logo" className="h-6 w-6" />
            <div className={`font-bold text-2xl`}>Superfone</div>
          </div>
        </Link>
      }
      styles={{
        body: {
          height: "100vh",
          overflowY: "hidden",
        },
      }}
    >
      <div className="overflow-y-auto h-[calc(100vh_-_100px)]">
        <AppMenu />
      </div>
    </Drawer>
  ) : (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={"60px"}
      width={"220px"}
      theme="light"
      style={{ flexGrow: 1 }}
    >
      <div className="flex flex-col grow h-full">
        <Link to="/">
          <div className="border-b-2 border-[#dfe6ed] min-h-16 flex gap-2 items-center px-4">
            <img src={Logo} alt="logo" className="h-6 w-6" />
            <div className={`font-bold text-2xl ${collapsed ? "hidden" : ""}`}>
              Superfone
            </div>
          </div>
        </Link>
        <div className="overflow-y-auto grow overflow-x-hidden border-r-2 border-[#dfe6ed] flex flex-col gap-4">
          <div className="grow overflow-y-auto">
            <AppMenu />
          </div>
          <SiderButtons collapsed={collapsed} />
        </div>
      </div>
    </Layout.Sider>
  );
};

const SiderButtons = (props: Readonly<{ collapsed: boolean }>) => {
  const { collapsed } = props;
  const orgId = useSelector(selectOrgIdToPayFor);
  const navigate = useNavigate();
  const onClick = () => {
    navigate(AppRoutesObject.ContactUs.url);
  };
  const { data } = useUsersSyncQuery();
  const [delayedCollapsed, setDelayedCollapsed] = useState(false);
  useEffect(() => {
    if (collapsed) {
      setDelayedCollapsed(collapsed);
    } else {
      setTimeout(() => {
        setDelayedCollapsed(collapsed);
      }, 150);
    }
  }, [collapsed]);

  const { data: user } = useGetMemberQuery(data?.user?.id || 0, {
    skip: !data?.user?.id,
  });

  const isLoggedInUserStaff =
    user?.orgs?.every((o) => o?.role_slug === "member") || false;
  const { isFreeTier, summaries, isAllInOneProPlan } = useGetSummariesQuery(
    undefined,
    {
      selectFromResult: ({ data }) => selectSummariesWithFlags(data || []),
    }
  );
  const nonProOrgId =
    summaries?.find(
      (s) =>
        !getPlanLevel(s?.subscription?.plan_id).isProOldOrNew && !s?.is_trial
    )?.org_id || 0;

  const [loginOrg, { isFetching, isLoading }] = useLazyLoginOrgQuery();
  const dispatch = useDispatch();

  if (delayedCollapsed) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 px-2 pb-2">
      {isAllInOneProPlan ? null : (
        <Spin spinning={isLoading || isFetching}>
          <button
            onClick={async () => {
              await loginOrg(nonProOrgId);
              dispatch(setUpgradeOrgId(nonProOrgId));
            }}
            className="bg-[#9857bd] cursor-pointer w-full px-3 py-1 rounded-lg"
          >
            <div className="flex gap-2 items-center justify-between">
              <div className="flex gap-2 items-center">
                <div className="uppercase text-white text-[13px] leading-5 font-medium">
                  All-in-one{" "}
                </div>
                <img
                  src={Pro}
                  alt="pro"
                  className="w-[52px] h-[22px] min-h-10"
                />
              </div>
              <img
                src={ChevronRightWhite}
                className="w-5 h-5"
                alt="chevron-right-white"
              />
            </div>
          </button>
        </Spin>
      )}
      <Button
        title="Watch tutorial"
        type="primary"
        icon={<YoutubeOutlined />}
        onClick={() => {
          window.open("https://www.youtube.com/watch?v=1Qtqvzcblyg");
        }}
      >
        <span className={delayedCollapsed ? "!hidden" : ""}>
          Watch tutorial
        </span>
      </Button>
      {isLoggedInUserStaff ? null : (
        <>
          {isFreeTier ? (
            <Button
              type="primary"
              onClick={() => {
                contactUs(`I would like to upgrade from my free tier plan`);
              }}
            >
              Upgrade
            </Button>
          ) : (summaries?.length || 0) === 1 && summaries?.[0]?.is_trial ? (
            <PayNowButton
              title="Pay Now"
              {...(delayedCollapsed
                ? { icon: <PlusCircleOutlined />, textClassName: "!hidden" }
                : {})}
              orgId={orgId}
            />
          ) : (
            <PurchaseButton
              title="Buy a new Number !"
              {...(delayedCollapsed
                ? { icon: <PlusCircleOutlined />, textClassName: "!hidden" }
                : {})}
            />
          )}
        </>
      )}
      <Button title="Contact Us" icon={<PhoneOutlined />} onClick={onClick}>
        <span className={delayedCollapsed ? "!hidden" : ""}>Contact Us</span>
      </Button>
      {delayedCollapsed ? (
        <Button
          title="Chat with live agent"
          icon={<LinkOutlined style={{ color: "blue" }} />}
          onClick={() => {
            contactUs("I need help");
          }}
        >
          <span className={delayedCollapsed ? "!hidden" : ""}>Link</span>
        </Button>
      ) : (
        <Typography.Title
          title="Chat with live agent"
          onClick={() => {
            contactUs("I need help");
          }}
          style={{
            textDecoration: "underline",
            fontSize: "1rem",
            textAlign: "center",
            cursor: "pointer",
            color: "blue",
          }}
        >
          Chat with live agent
        </Typography.Title>
      )}
    </div>
  );
};

const AppMenu = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const siderCollapsed = useSelector(selectSiderCollapsed);
  const onClick = () => {
    if (isScreenSmaller) {
      dispatch(setSidebar(!siderCollapsed));
    }
  };
  const pendingActionAlert = useSelector(showPendingActionAlert);
  const { data: usersSync } = useUsersSyncQuery();
  const { isFreeTier } = useGetSummariesQuery(undefined, {
    selectFromResult: ({ data }) => selectIsFreeTier(data || []),
  });
  const items = [
    // getItem(AppRoutesObject.GetStarted, <span>🚀</span>),
    getItem(AppRoutesObject.Home, <HomeOutlined />),
    getItem(
      AppRoutesObject.Teams,
      <SwitcherOutlined />,
      undefined,
      undefined,
      pendingActionAlert ? true : undefined
    ),
    getItem(AppRoutesObject.Staff, <TeamOutlined />),
    getItem(AppRoutesObject.Contacts, <ContactsOutlined />),
    getItem(AppRoutesObject.Tasks, <FormOutlined />),
    ...(isFreeTier
      ? []
      : [
          getItem(AppRoutesObject.CallHistory, <PhoneOutlined />),
          getItem(AppRoutesObject.Insights, <BarChartOutlined />),
        ]),
    getItem(AppRoutesObject.Notes, <AlignLeftOutlined />),
    getItem(AppRoutesObject.Whatsapp, <WhatsAppOutlined />),
    getItem(AppRoutesObject.Broadcast, <WhatsAppOutlined />),
    getItem(AppRoutesObject.Reports, <PieChartOutlined />),
    getItem(AppRoutesObject.Settings, <SettingOutlined />),
    getItem(AppRoutesObject.Integrations, <SnippetsOutlined />),
    ...([145954, 39028, 51996, 18805].includes(usersSync?.org?.id || 0)
      ? [getItem(AppRoutesObject.Automations, <RobotOutlined />)]
      : []),
  ];

  return (
    <Menu
      mode="inline"
      onClick={onClick}
      theme="light"
      selectedKeys={[`/${location.pathname.split("/")[1]}`]}
      items={items}
    />
  );
};

export const getItem = (
  route: AppRoute,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
  showAlert: boolean = false,
  showUpdate: boolean = false
): MenuItem => {
  const label = route.Component ? (
    <Link
      style={{ display: "block", width: "100%", height: "100%" }}
      to={route.url}
    >
      {showUpdate || showAlert ? (
        <Row justify="space-between" align="middle">
          <div>{route.text}</div>
          {showAlert ? (
            <WarningFilled style={{ color: "#faad14", fontSize: "1.5rem" }} />
          ) : showUpdate ? (
            <New />
          ) : null}
        </Row>
      ) : (
        <div>{route.text}</div>
      )}
    </Link>
  ) : (
    route.text
  );
  return {
    key: route.url,
    icon,
    children,
    label,
    type,
    title: route.text,
  } as MenuItem;
};

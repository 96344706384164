import { getContactWithCountryCode } from "../../../utils/remove-country-code";

import Pdf from "../../../assets/images/PDF.png";

import Audio from "../../../assets/images/Audio.png";
import FileIcon from "../../../assets/images/File.svg";
import CSVIcon from "../../../assets/images/csv.svg";
import {
  DraftStagesType,
  RightContextMenuType,
  ScrollToIndexType,
  initialDraftStage,
  setInitialRepliedToAction,
  setInitialRightContextMenuAction,
  setUpdateRightContextMenuAction
} from "../../../store/reducer/wp-chat.reducer";
import { useEffect } from "react";
import { io } from "socket.io-client";
import dayjs from "dayjs";

export const orgSuperfoneNumber = (org: any) => {
  const { phone: a } = getContactWithCountryCode({
    number: org?.superfone_number || undefined,
    country: org?.country
  });

  return a;
};

export function getInitials(name: string): string {
  try {
    const nameParts = name?.split(" ");

    if (nameParts.length >= 2) {
      const firstInitial = nameParts[0].charAt(0).toUpperCase();
      const lastInitial = nameParts[1].charAt(0).toUpperCase();

      return `${firstInitial}${lastInitial}`;
    } else {
      // Handle cases where the input is just a single word
      return name.charAt(0).toUpperCase();
    }
  } catch (error) {
    return name;
  }
}

export function formatTime(dateString: string): string {
  const time = dateString ? dayjs(dateString).format("hh:mm a") : "";

  return time;
  const date = new Date(dateString);

  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour time to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format minutes to always be two digits
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${formattedMinutes} ${ampm}`;
}

export function getDiffInDays(dateString: string): string {
  let date1 = new Date(dateString);
  let date2 = new Date();

  let Difference_In_Time = date2.getTime() - date1.getTime();

  let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
  return `${Difference_In_Days}`;
}

export const userName = (usersSync: any) => `${usersSync?.user?.first_name || ""} ${usersSync?.user?.last_name || ""}`.trim();

export const getMajorIcon = (fileType: string, file: File) => {
  const file_type = fileType?.toLowerCase() || "";
  if (file_type.includes("image")) {
    return URL.createObjectURL(file);
  } else if (file_type.includes("pdf")) {
    return Pdf;
  } else if (file_type.includes("audio")) {
    return Audio;
  } else if (file_type.includes("csv")) {
    return CSVIcon;
  } else {
    return FileIcon;
  }
};

export const getFileIcon = (extension: string) => {
  switch (extension) {
    case "pdf":
      return Pdf;
    case "csv":
      return CSVIcon;
    default:
      return FileIcon;
  }
};

export const handleRightContextMenuClick = (key: string, value: boolean, rightContextMenu: RightContextMenuType, dispatch: any) => {
  dispatch(setInitialRepliedToAction());
  dispatch(setInitialRightContextMenuAction());
  dispatch(setUpdateRightContextMenuAction({ ...rightContextMenu, [key]: value }));
};

export const MessageTypesEnum = Object.freeze({
  TEXT: "text",
  AUDIO: "audio",
  DOCUMENT: "document",
  IMAGE: "image",
  VIDEO: "video",
  TEMPLATE: "template",
  BUTTON: "button",
  LOCATION: "location",
  INTERACTIVE: "interactive",
  STICKER: "sticker",
  CONTACTS: "contacts",
  REACTIONS: "reactions"
});

/**
 *
 * @param ref reference of dom element
 * @param cb handles functionality when user click outside the referenced element
 */
export function useOutsideAlerter(ref: any, cb: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref?.current && !ref?.current?.contains?.(event.target)) {
        cb();
      }
    } // Bind the event listener
    document?.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document?.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const emojiIcons: string[] = ["👍", "❤️", "😂", "😯", "😢", "🙏"];

export function formatDate(dateString: string): string {
  const date = new Date(dateString);

  // Get day and month name
  const day = date.getUTCDate();
  const month = date.toLocaleString("en-US", { month: "long" }); // Full month name (e.g., August)

  // Return the formatted date in "DD Month" format
  return `${day} ${month}`;
}

export const SocketUrl = "https://prod-api.superfone.co.in/superfone/socket.io/";

export const getHoursRemaining = (targetTime: any) => {
  // Current time
  const now: any = new Date();

  // Target time
  targetTime = new Date(targetTime);

  // Calculate the difference in milliseconds
  const timeDifference = targetTime - now;

  // Convert the difference to hours
  const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));

  // console.log(`Hours left: ${hoursLeft}`);

  if (hoursLeft < 0) {
    return 0;
  }

  return hoursLeft;
};

export const FileDocType = {
  MP4: ".mp4",
  TTF: ".ttf",
  APK: ".apk",
  DAT: ".dat",
  ISO: ".iso",
  SQL: ".sql",
  SVG: ".svg",
  GIF: ".gif",
  RAR: ".rar",
  XML: ".xml",
  AI: ".ai",
  AVI: ".avi",
  ZIP: ".zip",
  JPG: ".jpg",
  CAB: ".cab",
  XD: ".xd",
  TXT: ".txt",
  BAT: ".bat",
  ICO: ".ico",
  XXL: ".xxl",
  XLR: ".xlr",
  DOC: ".doc",
  PPTX: ".pptx",
  CDR: ".cdr",
  PNG: ".png",
  EXE: ".exe",
  ASF: ".asf",
  MOV: ".mov",
  DMG: ".dmg",
  "3DS": ".3ds",
  "3GP": ".3gp",
  DLL: ".dll",
  AAC: ".aac",
  MP3: ".mp3",
  MPG: ".mpg",
  FLA: ".fla",
  MIDI: ".midi",
  CAD: ".cad",
  HTML: ".html",
  URL: ".url",
  INDD: ".indd",
  CSS: ".css",
  XLS: ".xls",
  TIF: ".tif",
  OTF: ".otf",
  IFF: ".iff",
  EPS: ".eps",
  CPP: ".cpp",
  FLV: ".flv",
  BMP: ".bmp",
  OBJ: ".obj",
  PPT: ".ppt",
  ID: ".id",
  PHP: ".php",
  TEX: ".tex",
  RSS: ".rss",
  TIFF: ".tiff",
  RAW: ".raw",
  WAV: ".wav",
  PDF: ".pdf"
};

export const getFileIconWrtDocType = () => {};

export const addContactToDraftStage = (contactID: string, draftStages: DraftStagesType[]) => {
  let arrDraftStage: DraftStagesType[] = JSON.parse(JSON.stringify(draftStages));

  const isContactExisted = arrDraftStage?.find((ds) => ds?.contactID === contactID);

  if (!isContactExisted) arrDraftStage?.push({ ...initialDraftStage, contactID: contactID });

  return [...arrDraftStage];
};

export const getActiveDraftStage = (contactID: string, draftStages: DraftStagesType[]) => {
  let arrDraftStage: DraftStagesType[] = JSON.parse(JSON.stringify(draftStages));

  const activeDraft = arrDraftStage?.find((ds) => ds?.contactID === contactID);

  return activeDraft;
};

export const updateActiveDraftStage = (contactID: string, draftStages: DraftStagesType[], message?: string, scrollState?: ScrollToIndexType) => {
  let arrDraftStage: DraftStagesType[] = JSON.parse(JSON.stringify(draftStages));
  arrDraftStage?.forEach((ds) => {
    if (ds?.contactID === contactID) {
      if (typeof message === "string" && message !== undefined) ds.message = message;
      if (typeof scrollState === "object" && scrollState !== undefined) ds.scrollState = { ...scrollState };
    }
  });

  return [...arrDraftStage];
};

export function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
  let timer: NodeJS.Timeout;
  return function (...args: Parameters<T>) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export function formatSearchDate(dateString: string): string {
	const date = dateString ? dayjs(dateString).format("D MMM") : "";
	return date;
}